import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { computed, inject, Injectable, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import saveAs from 'file-saver';
import moment from 'moment';
import {
  distinctUntilChanged,
  filter,
  finalize,
  map,
  mergeMap,
  Observable,
  startWith,
  switchMap,
  take,
  takeWhile,
  timer,
} from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AlertService } from '../../../partage/components/alert/alert.service';
import { FactureService } from '../../facturation/facture.service';
import {
  AcompteCreation,
  AcompteStatut,
  AcompteSuccess,
  Client,
  Evenement,
  EvenementDataTable,
  EvenementFilter,
  PdfActon,
  RedditionCreation,
  RedditionStatut,
  RedditionSuccess,
} from './evenements.interface';
import {
  HistoriqueOperationCommerciale,
  OperationsCommerciales,
  RapprochementDataTable,
  RapprochementFilter,
} from './panel-evenement-detail/panel-evenement-detail/panel-evenement-detail-interface';

@Injectable({
  providedIn: 'root',
})
export class EvenementsService {
  private _httpClient = inject(HttpClient);
  private alertService = inject(AlertService);
  private factureService = inject(FactureService);

  filterClient = signal('');
  clientSelection = signal<Client | undefined>(undefined);
  filterform = signal<EvenementFilter | undefined>(undefined);
  evenementSelection = signal<Evenement | undefined>(undefined);
  voirDetail = signal<boolean>(false);
  urlClient = signal('');

  private clients$ = this._httpClient.get<Client[]>(
    `${environment.backendUrl}redditions/liste-clients`
  );

  private clients = toSignal<Client[]>(
    this.clients$.pipe(startWith([] as Client[]))
  );

  filteredClient = computed(() => {
    const clients = this.clients();
    const filter = this.filterClient();
    if (clients) {
      return clients.filter((c) =>
        c.libelleClient.toLowerCase().includes(filter)
      );
    }
    return [] as Client[];
  });

  booltest = signal<boolean>(false);

  private evenements$ = toObservable(this.filterform).pipe(
    filter(Boolean),
    switchMap((filter) => {
      let queryparams = new HttpParams();
      queryparams = queryparams.appendAll({
        pageIndex: filter.numeroPage,
        pageSize: filter.nbrParPage,
        orderingField: filter.orderingField,
        orderingDirection: filter.orderingDirection,
        codeClients: filter.codeClients,
        rechercheEvenement: filter.rechercheEvenement,
        aRedditionnerUniquement: filter.aRedditionnerUniquement,
      });

      if (moment(filter.filtreDateDebut).isValid()) {
        queryparams = queryparams.appendAll({
          filtreDateDebut: moment(filter.filtreDateDebut).format('YYYY-MM-DD'),
        });
      }
      if (moment(filter.filtreDateFin).isValid()) {
        queryparams = queryparams.appendAll({
          filtreDateFin: moment(filter.filtreDateFin).format('YYYY-MM-DD'),
        });
      }
      const href = `${environment.backendUrl}redditions/evenements`;
      return this._httpClient.get<EvenementDataTable>(href, {
        params: queryparams,
      });
    })
  );

  private resultEvt = toSignal(this.evenements$, {
    initialValue: {} as EvenementDataTable,
  });

  evenement = computed(() => this.resultEvt() ?? ({} as EvenementDataTable));

  private clientPreFilter = toSignal(
    toObservable(this.clients).pipe(
      filter(() => this.urlClient() != ''),
      map((cli) => cli?.find((c) => c.codeClient === this.urlClient())),
      filter(Boolean),
      map((client) => {
        this.clientSelection.set(client);
        this.urlClient.set('');
      })
    )
  );

  private redditionnerEvenement$(
    idEvenement: string,
    idCompteBancaire: string
  ): Observable<RedditionSuccess> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    const href = `${environment.backendUrl}redditions/evenements/${idEvenement}/redditionner`;
    return this._httpClient.post<RedditionSuccess>(
      href,
      { idCompteBancaire: idCompteBancaire },
      httpOptions
    );
  }

  acompteACreer = signal<AcompteCreation | undefined>(undefined);
  acompteSuccess = signal<AcompteSuccess | undefined>(undefined);
  redditionACreer = signal<RedditionCreation | undefined>(undefined);
  redditionSuccess = signal<RedditionSuccess | undefined>(undefined);
  factureEnCours = signal<boolean>(false);
  acompteStatut = signal<AcompteStatut | undefined>(undefined);
  redditionStatut = signal<RedditionStatut | undefined>(undefined);

  private creerAcompte = toSignal(
    toObservable(this.acompteACreer).pipe(
      filter(Boolean),
      switchMap((acompte) => {
        const httpOptions = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        };
        const href = `${environment.backendUrl}redditions/evenements/${acompte.idEvenement}/faire-acompte`;
        return this._httpClient
          .post<AcompteSuccess>(
            href,
            {
              montantAcompte: acompte.montantAcompte,
              idCompteBancaire: acompte.idCompteBancaire,
            },
            httpOptions
          )
          .pipe(
            map((success) => {
              const filter = this.filterform();
              const nouveauFiltre: EvenementFilter = {
                numeroPage: filter!.numeroPage,
                nbrParPage: filter!.nbrParPage,
                orderingField: filter!.orderingField,
                orderingDirection: filter!.orderingDirection,
                codeClients: filter!.codeClients,
                rechercheEvenement: filter!.rechercheEvenement,
                aRedditionnerUniquement: filter!.aRedditionnerUniquement,
                filtreDateDebut: filter!.filtreDateDebut,
                filtreDateFin: filter!.filtreDateFin,
              };
              this.filterform.set(nouveauFiltre); //permet de reload le tableau des evts
              this.acompteSuccess.set(success);
              this.factureEnCours.set(true);
              this.acompteACreer.set(undefined);
            })
          );
      })
    )
  );

  private getAcompte = toSignal(
    toObservable(this.acompteSuccess).pipe(
      filter(Boolean),
      switchMap((success) => {
        return timer(0, 3000)
          .pipe(
            mergeMap(() => this.recupererStatutAcompte$(success.idAcompte)),
            takeWhile(
              (response) => response.statutAcompte !== 'JustificatifGeneree',
              true
            ),
            take(12),
            finalize(() => {
              this.factureEnCours.set(false);
              this.acompteSuccess.set(undefined);
              const statut = this.acompteStatut();
              if (!statut || statut.statutAcompte !== 'JustificatifGeneree') {
                this.alertService.error('Telechargement impossible.', {
                  autoClose: false,
                });
              }
            })
          )
          .pipe(
            map((statutEnCours) => {
              this.acompteStatut.set(statutEnCours);
              if (statutEnCours.statutAcompte === 'JustificatifGeneree') {
                this.factureService.acompteAction.set({
                  id: statutEnCours.idAcompte,
                  action: PdfActon.telecharger,
                });
              }
            })
          );
      })
    )
  );

  private creerReddition = toSignal(
    toObservable(this.redditionACreer).pipe(
      filter(Boolean),
      switchMap((reddition) => {
        const httpOptions = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        };
        const href = `${environment.backendUrl}redditions/evenements/${reddition.idEvenement}/redditionner`;
        return this._httpClient
          .post<RedditionSuccess>(
            href,
            { idCompteBancaire: reddition.idCompteBancaire },
            httpOptions
          )
          .pipe(
            map((success) => {
              const filter = this.filterform();
              const nouveauFiltre: EvenementFilter = {
                numeroPage: filter!.numeroPage,
                nbrParPage: filter!.nbrParPage,
                orderingField: filter!.orderingField,
                orderingDirection: filter!.orderingDirection,
                codeClients: filter!.codeClients,
                rechercheEvenement: filter!.rechercheEvenement,
                aRedditionnerUniquement: filter!.aRedditionnerUniquement,
                filtreDateDebut: filter!.filtreDateDebut,
                filtreDateFin: filter!.filtreDateFin,
              };
              this.filterform.set(nouveauFiltre); //permet de reload le tableau des evts
              this.redditionSuccess.set(success);
              this.factureEnCours.set(true);
              this.redditionACreer.set(undefined);
            })
          );
      })
    )
  );

  private getReddition = toSignal(
    toObservable(this.redditionSuccess).pipe(
      filter(Boolean),
      switchMap((success) => {
        return timer(0, 3000)
          .pipe(
            mergeMap(() => this.recupererStatutReddition$(success.idReddition)),
            takeWhile(
              (response) => response.statutReddition !== 'FactureGeneree',
              true
            ),
            take(12),
            finalize(() => {
              this.factureEnCours.set(false);
              this.redditionSuccess.set(undefined);
              const statut = this.redditionStatut();
              if (!statut || statut.statutReddition !== 'FactureGeneree') {
                this.alertService.error('Telechargement impossible.', {
                  autoClose: false,
                });
              }
            })
          )
          .pipe(
            map((statutEnCours) => {
              this.redditionStatut.set(statutEnCours);
              if (statutEnCours.statutReddition === 'FactureGeneree') {
                this.factureService.redditionAction.set({
                  id: statutEnCours.idReddition,
                  action: PdfActon.telecharger,
                });
              }
            })
          );
      })
    )
  );

  private recupererOpertationCommerciale$ = toObservable(
    this.evenementSelection
  ).pipe(
    filter(Boolean),
    switchMap((evt) => {
      const href = `${environment.backendUrl}redditions/evenements/${
        evt!.id
      }/operations-commerciales`;
      return this._httpClient.get<OperationsCommerciales>(href);
    })
  );

  private recupererOpertationCommerciale = toSignal(
    this.recupererOpertationCommerciale$
  );

  listeAcompte = computed(
    () => this.recupererOpertationCommerciale()?.acomptes
  );
  reddition = computed(() => this.recupererOpertationCommerciale()?.reddition);

  chargerRapprochement = signal<boolean>(false);
  rapprochementFitler = signal<RapprochementFilter | undefined>(undefined);
  listeRapprochement = signal<RapprochementDataTable | undefined>(undefined);
  totalCommande = computed(() => this.listeRapprochement()?.totalCount);

  private recupRapproBancaire = computed(() => ({
    filter: this.rapprochementFitler(),
    evt: this.evenementSelection(),
    charger: this.chargerRapprochement(),
  }));

  private recupererRapprochementBancaire = toSignal(
    toObservable(this.recupRapproBancaire).pipe(
      filter((x) => x.charger && x.evt != undefined && x.filter != undefined),
      distinctUntilChanged(
        (prev, curr) =>
          prev.evt?.id === curr.evt?.id &&
          JSON.stringify(prev.filter) === JSON.stringify(curr.filter)
      ),
      switchMap((recup) => {
        let queryparams = new HttpParams();
        queryparams = queryparams.appendAll({
          pageIndex: recup.filter!.numeroPage,
          pageSize: recup.filter!.nbrParPage,

          filtreStatutRapprochement: recup.filter!.statut,
        });
        if (recup.filter!.numeroCmd) {
          queryparams = queryparams.appendAll({
            filtreIdCommandeAparte: recup.filter!.numeroCmd,
          });
        }
        const href = `${
          environment.backendUrl
        }resumes-commandes/par-evenement/${recup.evt!.id}`;
        return this._httpClient
          .get<RapprochementDataTable>(href, {
            params: queryparams,
          })
          .pipe(
            map((result) => {
              this.listeRapprochement.set(result);
            })
          );
      })
    )
  );

  private recupererStatutReddition$(
    idReddition: string
  ): Observable<RedditionStatut> {
    const href = `${environment.backendUrl}redditions/redditions/${idReddition}/infos`;
    return this._httpClient.get<RedditionStatut>(href);
  }

  private recupererStatutAcompte$(
    idAcompte: string
  ): Observable<AcompteStatut> {
    const href = `${environment.backendUrl}redditions/acomptes/${idAcompte}/infos`;
    return this._httpClient.get<AcompteStatut>(href);
  }

  idDetailATelecharger = signal('');
  nomFichierDetail = signal('');

  private telechargerDetailReddition$ = toSignal(
    toObservable(this.idDetailATelecharger).pipe(
      filter((id) => id != ''),
      switchMap((id) => {
        const url = `${environment.backendUrl}redditions/evenements/${id}/reddition/detail`;
        return this._httpClient.get(url, { responseType: 'blob' });
      }),
      map((blob) => {
        saveAs(blob, this.nomFichierDetail());
      })
    )
  );

  idHistoriqueAcompte = signal<string>('');
  idHistoriqueReddition = signal<string>('');

  private recupererHistoriqueAcompte = toSignal(
    toObservable(this.idHistoriqueAcompte)
      .pipe(
        filter((id) => id !== ''),
        filter((id) => {
          return (
            this.listeAcompte()!.find((ac) => ac.id === id)!.historique ===
            undefined
          );
        }),
        switchMap((id) => {
          const url = `${environment.backendUrl}historique-operations-commerciales/acomptes/${id}`;
          return this._httpClient.get<HistoriqueOperationCommerciale>(url);
        })
      )
      .pipe(
        map((histo) => {
          this.listeAcompte()!.find(
            (ac) => ac.id === histo.idAcompte
          )!.historique = histo;
        })
      )
  );
  private recupererHistoriqueReddition = toSignal(
    toObservable(this.idHistoriqueReddition)
      .pipe(
        filter((id) => id !== ''),
        switchMap((id) => {
          const url = `${environment.backendUrl}historique-operations-commerciales/redditions/${id}`;
          return this._httpClient.get<HistoriqueOperationCommerciale>(url);
        })
      )
      .pipe(
        map((histo) => {
          this.reddition()!.historique = histo;
        })
      )
  );
}
