export class Alert {
  id!: string;
  type!: AlertType;
  message!: string;
  autoClose!: boolean;
  keepAfterRouteChange!: boolean;
  fade!: boolean;
  numeroError!: string;

  constructor(init?: Partial<Alert>) {
    Object.assign(this, init);
    this.numeroError = Date.now().toString(36);
    this.fade = false;
  }
}

export interface Errors {
  type: string;
  title: string;
  status: number;
  errors: ErrorPerso[];
}

export interface ErrorPerso {
  name: string;
  description: string;
  type: number;
}

export enum AlertType {
  Success,
  Error,
  Info,
  Warning,
}
