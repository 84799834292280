import { Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs';
import { Alert, AlertType } from './alert.interface';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor() {}

  message = signal(false);
  alerts = signal<Alert[]>([]);
  fade = signal<boolean>(true);
  timer = signal<number>(300);

  private defaultId = 'default-alert';

  private subject = signal<Alert>({} as Alert);

  removeAlert(alert: Alert) {
    if (!this.alerts().includes(alert)) return;
    if (this.fade()) {
      const al = this.alerts().find(
        (al) => al.numeroError === alert.numeroError
      );
      if (al) {
        al.fade = true;
      }
      //suppression apres l'effet fade css.
      setTimeout(() => {
        this.alerts.update((item) =>
          item.filter((al) => al.numeroError !== alert.numeroError)
        );
      }, 500);
    } else {
      this.alerts.update((item) =>
        item.filter((al) => al.numeroError !== alert.numeroError)
      );
    }
  }

  private onAlert = toObservable(this.subject)
    .pipe(filter((x) => x && x.id === this.defaultId))
    .subscribe((alert) => {
      if (!alert.message) {
        this.alerts.update((x) => x.filter((y) => y.keepAfterRouteChange));
        return;
      }
      this.alerts.update((x) => [...x, alert]);

      if (alert.autoClose) {
        if (alert.type === AlertType.Success) {
          this.timer.set(3000);
        } else {
          this.timer.set(5000);
        }
        setTimeout(() => this.removeAlert(alert), this.timer());
      }
    });

  success(message: string, options?: any) {
    this.alert(new Alert({ ...options, type: AlertType.Success, message }));
  }

  error(message: string, options?: any) {
    this.alert(
      new Alert({
        ...options,
        type: AlertType.Error,
        message,
      })
    );
  }

  info(message: string, options?: any) {
    this.alert(new Alert({ ...options, type: AlertType.Info, message }));
  }

  warn(message: string, options?: any) {
    this.alert(new Alert({ ...options, type: AlertType.Warning, message }));
  }

  // methode de création des alertes
  private alert(alert: Alert) {
    alert.id = alert.id || this.defaultId;
    this.subject.set(alert);
  }

  // clear alerts
  clear(id = this.defaultId) {
    this.subject.set(new Alert({ id }));
  }

  getCssClass(type: AlertType) {
    const classes = ['alert fade'];
    const alertTypeClass = {
      [AlertType.Success]: 'alert-success',
      [AlertType.Error]: 'alert-error',
      [AlertType.Info]: 'alert-info',
      [AlertType.Warning]: 'alert-warning',
    };
    classes.push(alertTypeClass[type]);

    return classes.join(' ');
  }
}
